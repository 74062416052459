<template>
  <div class="iss-main theme-bg">
    <div class="repeatPhone-content">
      <tips-bar text="新增有效联系人中姓名或电话与已有联系人重复，请手动确认。">
        <template #btnList>
          <div class="btn" @click="clickHandle">完成</div>
        </template>
      </tips-bar>
      <!--   todo 表格折叠   -->
      <div class="operation">
        <div class="operation-table">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            tableTitle="手机号重复"
            formWidth="300px"
            :items="items"
            :urlParams="urlParams"
            :url="url"
            :btn-operation="btnOperation"
            :showHeader="true"
            :allowSelection="{
              hideAllSelect: true,
              type: 'checkbox',
            }"
            :row-selection="{ type: 'checkbox' }"
            :scroll="{ y: gridHeight }"
            @fnGridPhone="getGridData"
          >
            <template #selectAll="{ total }"> 全部（{{ total }}）</template>
            <template #avatar="{ record }">
              <div class="nameClass">
                <div class="surname" v-if="record.name?.length > 0">
                  {{ record.name?.charAt(0) }}
                </div>
                <!--                <div class="nameText" :title="record.name">{{ record.name }}</div>-->
              </div>
            </template>
            <template #infoList="{ record }">
              <span v-for="(i, index) in record.infoList" :key="i.id">
                {{ i.phone }}
                <span v-if="index !== record.infoList.length - 1">,</span>
              </span>
            </template>
            <template #isRepeat>
              <a-tag color="orange"> 新增 </a-tag>
            </template>
            <template #expandedRowRender="{ record }">
              <div class="item-table">
                <a-table
                  :columns="columnsT"
                  :data-source="record.contactList"
                  :pagination="false"
                  :showHeader="false"
                  :bordered="false"
                >
                  <template #name="{ text }">
                    <div class="nameName">
                      <div class="expanded-name">
                        <div class="surname" v-if="text?.length > 0">
                          {{ text.charAt(0) }}
                        </div>
                        <div class="nameText">
                          {{ text }}
                        </div>
                      </div>

                      <!--                      <div class="surname" v-if="text?.length > 0">-->
                      <!--                        {{ text?.charAt(0) }}-->
                      <!--                      </div>-->
                      <!--                      <div class="nameText" :title="text">{{ text }}</div>-->
                    </div>
                  </template>

                  <template #contactList="{ record }">
                    <div class="expanded-phone" style="margin-left: 60px">
                      {{ record.phoneList?.join(',') }}
                    </div>
                  </template>
                  <template #isRepeat>
                    <a-tag color="blue"> 已有 </a-tag>
                  </template>
                </a-table>
              </div>
            </template>

            <!--            <template #operation="{ record }">-->
            <!--              <operation :options="options" :record="record" />-->
            <!--            </template>-->
          </grid>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="state.visible"
    title="合并联系人"
    @ok="mergePeople"
    :width="700"
  >
    <div>
      <ExclamationCircleFilled class="l-text-primary mr-10 fs-20" />
      <span class="fs-16">
        选择一个已有联系人合并，新增联系人将排除与已有联系人重复的号码，将剩余号码合并至选中联系人中。
      </span>
    </div>
    <div class="data-list">
      <div class="list-item" v-for="i in state.dataList" :key="i.id">
        <div class="item-f flex">
          <div class="flex">
            <div class="surname">{{ i.name?.charAt(0) }}</div>
            <div class="name">{{ i.name }}</div>
            <div class="ml-50 phoneList">{{ i.phoneList?.join(',') }}</div>
          </div>
          <div class="item-icon"><a-tag color="orange"> 新增 </a-tag></div>
        </div>

        <div class="item-child">
          <a-radio-group v-model:value="state.listValue">
            <a-radio
              v-for="item in i.contactList"
              :key="item.id"
              :value="item.id"
            >
              <!--              <div class="item-f">-->
              <!--                <div class="item66">-->
              <!--                  <div class="nameL">-->
              <!--                    <div class="surname ml-10">{{ item.name?.charAt(0) }}</div>-->
              <!--                    <div class="name">{{ item.name }}</div>-->
              <!--                  </div>-->
              <!--                  <div class="phoneList">{{ item.phoneList?.join(',') }}</div>-->
              <!--                </div>-->
              <!--                <div class="item-icon">-->
              <!--                  <a-tag color="blue"> 已有 </a-tag>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="expanded-rowT">
                <div class="flex mb-10 isfiex">
                  <div class="expanded-name">
                    <div class="surname">{{ item.name.charAt(0) }}</div>
                    <div class="nameText">{{ item.name }}</div>
                  </div>
                  <div class="expanded-phone">
                    {{ item.phoneList?.join(',') }}
                  </div>
                </div>

                <div class="expanded-icon">
                  <a-tag color="blue"> 已有 </a-tag>
                </div>
              </div>
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>
  </a-modal>
  <!--  <edit-page-one-->
  <!--      v-model:visible="state.visibleEdit"-->
  <!--      :init="state.initValue"-->
  <!--      @fnOk="handleAddPage"-->
  <!--  />-->
  <!--   打开ai弹窗 -->
  <open-ai
    v-model:visible="state.visibleAi"
    :init="state.initValue"
    @fnOk="handleOpenAi"
  />
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRoute, useRouter } from 'vue-router';
import Grid from '@/components/connGrid';
import { createVNode, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { Tag, Radio, message, Modal, Table } from 'ant-design-vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import dashboardApi from '@/api/dashboard.js';
import openAi from '@/views/dashboard/components/openAI';
import * as icons from '@ant-design/icons-vue';
// import editPageOne from '@/views/dashboard/components/editPageOne';
// import operation from '@/components/operation';

export default {
  name: 'valid',
  components: {
    tipsBar,
    Grid,
    ATag: Tag,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ExclamationCircleFilled,
    openAi,
    ATable: Table,
    // editPageOne,
    // operation,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const gridRef = ref();
    const state = reactive({
      loading: false,
      visible: false,
      dataList: [],
      listValue: '',
      visibleAi: false,
      initValue: {},
      showTip: false,
      // visibleEdit: false,
      // initValue: {},
    });

    const accomplish = () => {
      // 完成导入接口
      dashboardApi
        .accomplish({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          if (res) {
            router.push({
              path: '/contacts',
            });
          } else {
            // message.error('暂无可同步数据');
            Modal.confirm({
              title: `有效联系人已全部删除，请重新添加有效联系人。`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: '',
              okText: '好的',
              onOk: () => {
                router.push({
                  name: 'dashboard',
                  query: {
                    importStatus: route.query.importStatus,
                  },
                });
              },
            });
          }
        });
    };

    const clickHandle = () => {
      // router.push({
      //   name: 'dataRecognition',
      //   query: route.query,
      // });

      if (state.showTip) {
        //  出现提示
        message.error('重复联系人还未处理完');
      } else {
        // 出现弹窗
        // state.visibleAi = true;
        // state.initValue.formTo = 'repeatPhone';

        // 完成导入接口
        accomplish();
      }
    };

    // 设置用户导入行为页面信息 /dashboard/repeatPhone  设置8
    dashboardApi
      .setBehaviorPage({
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        pageName: '/dashboard/repeatPhone',
      })
      .then(res => {
        if (res) {
          console.log('res99', res);
        }
      });

    return {
      clickHandle,
      // mergePeople,
      state,
      gridRef,
      gridHeight: document.body.clientHeight - 280,
      url: '/connections_treasure_box/contact/import-contact/getEffectiveRepeatDataList',
      urlParams: {
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
      },
      items: [],
      btnOperation: [
        {
          type: 'delete',
          label: '删除',
          icon: '',
          btnType: 'primary',
          permission: 'clue:delete',
          fnClick: ids => {
            if (ids.length) {
              dashboardApi
                .removeByContactId({
                  userId: store.state.account.user.id,
                  ids,
                })
                .then(res => {
                  if (res) {
                    message.success('删除成功');
                    state.loading = false;
                    gridRef.value.refreshGrid(ids.length);
                  }
                })
                .catch(() => {
                  message.error('操作失败');
                  state.loading = false;
                });
            }
          },
        },
        {
          label: '合并',
          permission: 'clue:update',
          fnClick: (ids, data) => {
            if (ids.length && data.length > 0) {
              state.dataList = data.filter(i => {
                return ids.includes(i.id);
              });
              if (state.dataList.length > 1) {
                message.error('最多只能勾选一个联系人合并');
              } else {
                state.visible = true;
              }
            }
          },
        },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 180,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'infoList',
          slots: { customRender: 'infoList' },
          ellipsis: true,
        },
        {
          dataIndex: 'isRepeat',
          width: 120,
          fixed: 'right',
          slots: { customRender: 'isRepeat' },
          ellipsis: true,
        },
        // {
        //   key: 'id',
        //   fixed: 'right',
        //   width: 60,
        //   slots: { customRender: 'operation' },
        // },
      ],
      columnsT: [
        {
          dataIndex: 'name',
          width: 180,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          dataIndex: 'contactList',
          slots: { customRender: 'contactList' },
          ellipsis: true,
        },
        {
          dataIndex: 'isRepeat',
          width: 100,
          fixed: 'right',
          slots: { customRender: 'isRepeat' },
          ellipsis: true,
        },
      ],

      mergePeople() {
        if (state.loading || state.listValue.length == 0) return;
        state.loading = true;
        let parmas = [
          {
            userId: store.state.account.user.id,
            contactId: state.listValue,
            importContactId: state.dataList[0].id,
            contactIdList: state.dataList[0].contactList.map(i => i.id),
          },
        ];
        dashboardApi
          .mergeRepeatPhone(parmas)
          .then(res => {
            if (res) {
              message.success('合并成功');
              state.loading = false;
              state.visible = false;
              state.listValue = [];
              gridRef.value.refreshGrid();
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      },

      handleAddPage(value) {
        state.visibleEdit = value;
        gridRef.value.refreshGrid();
      },
      getGridData(value) {
        // let len = value.length ? value.length : value.records.length;
        if (value.length > 0) {
          state.showTip = true;
        } else {
          state.showTip = false;
        }
      },
      handleOpenAi(value) {
        state.visibleAi = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.repeatPhone-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
}
.expanded-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 20px;
  .expanded-name {
    width: 180px;
  }
}
.expanded-rowT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding-left: 60px;
  //padding-right: 20px;
  .expanded-name {
    width: 288px;
    .nameText {
      white-space: break-spaces;
      width: 250px;
      margin-right: 10px;
    }
  }
  .isfiex {
    flex: 1;
  }
  .expanded-phone {
    width: 225px;
    margin-right: 10px;
  }
}
.surname {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-color: @primary-color;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  margin-right: 8px;
  flex-shrink: 0;
}
.list-item {
  margin-top: 20px;
  padding: 10px;
  .item-f {
    justify-content: space-between;
    // .surname {
    // }
    .name {
      width: 86px;
    }
    .phoneList {
      width: 380px;
    }
  }
  .item-child {
    padding: 16px;
    padding-right: 0px;

    .item-f {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .item66 {
      display: flex;
      justify-content: space-between;
      width: 544px;
      align-items: center;
      //flex-wrap: wrap;
      // .surname {
      // }
      .name {
        width: 69px;
      }
      .phoneList {
        width: 391px;
        margin-right: 16px;
      }
      .phoneList /deep/ .ant-radio-wrapper {
        white-space: break-spaces;
      }
    }
    .item-icon {
      width: 48px;
    }
  }
}
:deep(.ant-table-tbody > tr > td) {
  background-color: #fff;
}
:deep(.ant-radio-group) {
  width: 100%;
}
:deep(.ant-radio-wrapper) {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0px;
  margin-bottom: 10px;
}
:deep(.ant-radio-wrapper span:nth-child(2)) {
  flex: 1;
  padding-right: 0px;
}
.data-list {
  height: 454px;
  overflow: auto;
}
.iss-main /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}
.nameName {
  width: 224px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // .surname {
  // }
  .nameText {
    width: 100px;
  }
}
.expanded-name {
  // width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  // .surname {
  // }
  .nameText {
    width: 180px;
    padding: 0 16px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
}
.item-table {
  padding-left: 44px;
  padding-right: 4px;
  :deep(.ant-table-tbody > tr > td) {
    border-bottom: none;
  }
  :deep(.ant-table-scroll) {
    overflow: hidden;
  }
}

/deep/ .ant-table-expanded-row td {
  padding: 0 16px !important;
}
</style>
